import { AuthService } from "src/app/tt-services/auth/auth.service";
import { JobRole } from "./../../tt-models/job-role.model";
import { Qualification } from "src/app/tt-models/qualification.model";
import { Skill } from "./../../tt-models/skill.model";
import { Industry } from "./../../tt-models/industry.model";
import { SubscriptionItem } from "./../../tt-models/subscription-item.model";
import { AngularFirestore } from "@angular/fire/firestore";
import { UserService } from "./../user/user.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireFunctions } from "@angular/fire/functions";
import { Injectable } from "@angular/core";

import { map, take } from "rxjs/operators";
import { of } from "rxjs";
import { JobType } from "./../../tt-models/job-type.model";
@Injectable({
  providedIn: "root",
})
export class StaticService {
  private industriesList: Industry[] = [];
  private skillsList: Skill[] = [];
  private qualificationsList: Qualification[] = [];
  private jobRolesList: JobRole[] = [];
  private jobTypesList: JobType[] = [];

  constructor(
    public auth: AngularFireAuth,
    private ttAuth: AuthService,
    private userService: UserService,
    private fs: AngularFirestore
  ) {}

  async loadData() {
    const staticCollection = this.fs.collection("global").doc("static");

    const docSnap = await staticCollection.get().pipe(take(1)).toPromise();
    const data = docSnap.data();

    this.skillsList = data.skills;
    this.qualificationsList = data.qualifications;
    this.industriesList = data.industries;
    this.jobRolesList = data.jobRoles;
    this.jobTypesList = data.jobTypes;
  }

  async addSuggestedSkill(skill) {
    const staticCollection = this.fs.collection("global").doc("static");

    const docSnap = await staticCollection.get().pipe(take(1)).toPromise();

    const data = docSnap.data();

    const suggestedSkills = data.suggestedSkills || [];

    suggestedSkills.push({
      uid: this.ttAuth.currentUser.id,
      id: skill.id,
      name: skill.name,
      created: new Date(),
    });

    const update = {};
    update["suggestedSkills"] = suggestedSkills;
    docSnap.ref.update(update);
  }

  async addSuggestedJobRole(qualification) {
    const staticCollection = this.fs.collection("global").doc("static");

    const docSnap = await staticCollection.get().pipe(take(1)).toPromise();

    const data = docSnap.data();

    const suggestedJobRoles = data.suggestedJobRoles || [];

    suggestedJobRoles.push({
      uid: this.ttAuth.currentUser.id,
      id: qualification.id,
      name: qualification.name,
      created: new Date(),
    });

    const update = {};
    update["suggestedJobRoles"] = suggestedJobRoles;
    docSnap.ref.update(update);
  }

  async addSuggestedQualification(qualification) {
    const staticCollection = this.fs.collection("global").doc("static");

    const docSnap = await staticCollection.get().pipe(take(1)).toPromise();

    const data = docSnap.data();

    const suggestedQualifications = data.suggestedQualifications || [];

    suggestedQualifications.push({
      uid: this.ttAuth.currentUser.id,
      id: qualification.id,
      name: qualification.name,
      created: new Date(),
    });

    const update = {};
    update["suggestedQualifications"] = suggestedQualifications;
    docSnap.ref.update(update);
  }

  get skills() {
    return this.skillsList;
  }

  get qualifications() {
    return this.qualificationsList;
  }

  get industries() {
    return this.industriesList;
  }

  get jobRoles() {
    return this.jobRolesList;
  }
  get jobTypes() {
    return this.jobTypesList;
  }

  // addData(id) {
  //   const staticCollection = this.fs.collection("global").doc("static");
  //   staticCollection.set({
  //     jobRoles: JobRole.JOB_ROLES,
  //     skills: Skill.SKILLS,
  //     industries: Industry.INDUSTRIES,
  //     qualifications: Qualification.QUALIFICATIONS,
  //   });
  // }
}
