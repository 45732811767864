import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { isDate } from 'util';

@Pipe({
  name: 'conversationLastMessageTime',
})
export class ConversationLastMessageTimePipe implements PipeTransform {
  transform(value: Date, ...args: any[]): any {
    if (isDate(value)) {
      return formatDate(value, 'h:mm a', 'en-US');
    } else {
      return '';
    }
  }
}
