import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() pageCount;
  @Output() onPageChange = new EventEmitter<number>();
  @Input() currentPage = 0;

  constructor() {}

  ngOnInit(): void {}

  get numberOfPagesarray() {
    return Array(this.pageCount);
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentPage: SimpleChange = changes.currentPage;

    if (currentPage.currentValue) {
      this.currentPage = currentPage.currentValue;
    }
  }

  goToPage(pageNum) {
    this.currentPage = pageNum;
    this.onPageChange.emit(pageNum);
  }

  nextPage() {
    this.onPageChange.emit(++this.currentPage);
  }

  previousPage() {
    this.onPageChange.emit(--this.currentPage);
  }
}
