<div class="row pb-5">
  <div class="col-md-8 col-sm-12">
    <h2>Talent Toute Candidate Verification</h2>
    <p>
      All candidates with the verification icon have had their key
      documentations checked by Talent Toute. This includes documents to support
      employment, including CV and proof of eligibility to work.
    </p>
    <p>
      We would always recommend that you still carry out relevant employment
      checks once an appointment with a candidate has been made in line with
      your own company policies and necessary employment legislation.
    </p>
  </div>
</div>
