import { PipesModule } from './../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { TtspinnerComponent } from './ttspinner/ttspinner.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { CommonModule } from '@angular/common';
@NgModule({
  imports: [CommonModule, PipesModule],
  declarations: [ProgressBarComponent, TtspinnerComponent, PaginatorComponent],
  exports: [ProgressBarComponent, TtspinnerComponent, PaginatorComponent],
})
export class ShareComponentsModule {}
