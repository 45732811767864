import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upgraded',
  templateUrl: './upgraded.component.html',
  styleUrls: ['./upgraded.component.scss']
})
export class UpgradedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
