import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/tt-services/auth/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-verify-business-page',
  templateUrl: './verify-business-page.component.html',
  styleUrls: ['./verify-business-page.component.scss'],
})
export class VerifyBusinessPageComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {}

  async logout() {
    await this.authService.logout();
    this.router.navigate(['/']);
  }
}
