export class SubscriptionItem {
  id: string;
  name: string;
  credits: number;
  acceptedPayments: Array<any>;
  price: number;
  label: string;
  order: number;

  constructor(
    id: string,
    name: string,
    subLabel: string,
    price: number,
    credits: number,
    acceptedPayments: Array<any>,
    order: number
  ) {
    this.id = id;
    this.name = name;
    this.label = subLabel;
    this.price = price;
    this.credits = credits;
    this.acceptedPayments = acceptedPayments;
    this.order = order;
  }

  static createSubscriptionItem(id, data) {
    return new SubscriptionItem(
      id,
      data.name,
      data.label,
      data.price,
      data.credits,
      data.acceptedPayments,
      data.order
    );
  }

  toJson() {
    return {
      name: this.name,
      label: this.label,
      price: this.price,
      credits: this.credits,
      acceptedPayments: this.acceptedPayments,
      order: this.order,
    };
  }
}
