import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { PipesModule } from './pipes/pipes.module';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VerifyEmailPageComponent } from './pages/verify-email-page/verify-email-page.component';
import { NgxStripeModule } from 'ngx-stripe';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ShareComponentsModule } from './components/SharedComponents.module';
import { REGION } from '@angular/fire/functions';
import { UpgradePageComponent } from './pages/upgrade-page/upgrade-page.component';
import { UpgradePaymentComponent } from './pages/upgrade-payment/upgrade-payment.component';
import { UpgradedComponent } from './pages/upgraded/upgraded.component';
import { VerifyBusinessPageComponent } from './pages/verify-business-page/verify-business-page.component';
import { AboutTtVerifiedPageComponent } from './pages/about-tt-verified-page/about-tt-verified-page.component';
import { ForgottenpasswordComponent } from './pages/forgottenpassword/forgottenpassword.component';
const providers = [{ provide: REGION, useValue: 'europe-west1' }];

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    HomePageComponent,
    RegisterPageComponent,
    VerifyEmailPageComponent,

    UpgradePageComponent,
    UpgradePaymentComponent,
    UpgradedComponent,
    VerifyBusinessPageComponent,
    AboutTtVerifiedPageComponent,
    ForgottenpasswordComponent,
  ],
  exports: [ReactiveFormsModule, ProgressBarComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgbModule,
    NgxStripeModule.forRoot(
      'pk_live_51H7FmGAfC0eF3EyvwWrIAKYXRJ17Reg2JlEgqu5locUbRo2Q6fCygCs3p6OYazubpFfzbyMPKh8bgLEngpZ8JR3i00clho36mo'
    ),

    ShareComponentsModule,
  ],
  providers: [...providers],
  bootstrap: [AppComponent],
})
export class AppModule {}
