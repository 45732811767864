import { JobType } from "./job-type.model";
import { Industry } from "src/app/tt-models/industry.model";
import { Subscription } from "./subscription.model";
import { Business } from "./business.model";
import { Candidate } from "./candidate.model";
import { Address } from "./address.model";
import { CandidateUserSettings } from "./candidate-settings.model";
import { TTVerification } from "./ttverification.model";
export class User {
  id: String;
  email: String;
  firstname: String;
  lastname: String;
  type: UserType;
  onboarded: Boolean;
  verified: Boolean;
  address: Address;
  mobileNumber: String;
  jobType: JobType;
  subscription: Subscription;
  industry: Industry;
  role: String;
  profile!: Candidate | Business;
  registeredOn: Date;

  settings: CandidateUserSettings;

  ttVerification: TTVerification;

  lastMessageReadAt: Date;
  lastMessageReceivedAt: Date;
  unreadMessageCount: number;

  constructor() {
    this.id = "";
    this.email = "";
    this.jobType = null;
    this.firstname = "";
    this.lastname = "";
    this.type = UserType.BUSINESS;
    this.onboarded = false;
    this.verified = false;
    this.address = new Address();
    this.mobileNumber = "";
    this.subscription = new Subscription();
    this.settings = null;
    this.ttVerification = new TTVerification();
    this.industry = null;
  }

  get shortID() {
    return this.id.substr(0, 6);
  }

  get hasActiveSubscription() {
    return (
      this.subscription &&
      this.subscription.name !== "trial" &&
      this.subscription.remainingCredits > 0
    );
  }

  get roleName() {
    switch (this.role) {
      case "0":
        return "Director/Owner";
        break;
      case "1":
        return "HR Professional";
        break;
      case "2":
        return "Line Manager";
        break;
      default:
        return "Other";
        break;
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  get fullName() {
    return (
      this.capitalizeFirstLetter(this.firstname) +
      " " +
      this.capitalizeFirstLetter(this.lastname)
    );
  }

  static createUser(id, userData) {
    const user = new User();
    user.id = id;
    user.type = userData.type;
    user.mobileNumber = userData.mobile;
    user.firstname = userData.firstname;
    user.lastname = userData.lastname;
    user.onboarded = userData.onboarded || false;
    user.verified = userData.verified || false;
    user.industry = userData.industry || null;
    user.jobType = userData.jobType || null;
    user.role = userData.role || "";

    user.registeredOn = userData.registeredOn
      ? (userData.registeredOn as firebase.default.firestore.Timestamp).toDate()
      : null;
    const address = new Address();
    if (userData.address) {
      address.line1 = userData.address.line1;
      address.line2 = userData.address.line2 || "";
      address.city = userData.address.city;
      address.postcode = userData.address.postcode || "";
    }

    user.address = address;

    user.ttVerification = TTVerification.createTTVerification(
      userData.ttVerification
    );

    user.lastMessageReadAt = userData.lastMessageReadAt
      ? (userData.lastMessageReadAt as firebase.default.firestore.Timestamp).toDate()
      : null;
    user.lastMessageReceivedAt = userData.lastMessageReceivedAt
      ? (userData.lastMessageReceivedAt as firebase.default.firestore.Timestamp).toDate()
      : null;
    user.unreadMessageCount = userData.unreadMessageCount || 0;

    //Subscription
    if (userData.subscription) {
      const subscription = new Subscription();
      subscription.label = userData.subscription.label;
      subscription.name = userData.subscription.name;
      subscription.remainingCredits = userData.subscription.remaining_credits;
      subscription.totalCredits = userData.subscription.total_credits;
      subscription.date =
        userData.subscription.date || new Date(user.registeredOn);

      user.subscription = subscription;
    }
    return user;
  }
}

export enum UserType {
  CANDIDATE = "candidate",
  BUSINESS = "business",
}
