<div class="row pb-3">
  <div class="col-12">
    <h2>Payment</h2>
  </div>
</div>
<div class="row pb-3" *ngIf="ready">
  <div class="col-md-8 col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="alert alert-danger" *ngIf="errorMsg">
          {{ errorMsg }}
        </div>
        <form novalidate [formGroup]="billingForm">
          <fieldset>
            <label>Billing contact</label>
            <div class="row">
              <div class="form-group col-md-6">
                <label>Firstname</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="billing_firstname"
                />
                <div
                  class="form-validation-errors"
                  *ngIf="
                    billingFirstname.invalid &&
                    (billingFirstname.dirty || billingFirstname.touched)
                  "
                >
                  <ng-container
                    *ngFor="
                      let validation of validation_messages.billing_firstname
                    "
                  >
                    <div
                      class="form-input-error-message"
                      *ngIf="billingFirstname.hasError(validation.type)"
                    >
                      {{ validation.message }}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Lastname</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="billing_lastname"
                />
                <div
                  class="form-validation-errors"
                  *ngIf="
                    billingLastname.invalid &&
                    (billingLastname.dirty || billingLastname.touched)
                  "
                >
                  <ng-container
                    *ngFor="
                      let validation of validation_messages.billing_lastname
                    "
                  >
                    <div
                      class="form-input-error-message"
                      *ngIf="billingLastname.hasError(validation.type)"
                    >
                      {{ validation.message }}
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>Address</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="billing_address1"
                />
                <div
                  class="form-validation-errors"
                  *ngIf="
                    billingAddress1.invalid &&
                    (billingAddress1.dirty || billingAddress1.touched)
                  "
                >
                  <ng-container
                    *ngFor="
                      let validation of validation_messages.billing_address1
                    "
                  >
                    <div
                      class="form-input-error-message"
                      *ngIf="billingAddress1.hasError(validation.type)"
                    >
                      {{ validation.message }}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>&nbsp;</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="billing_address2"
                />
              </div>

              <div class="form-group col-md-8">
                <label>City</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="billing_city"
                />
                <div
                  class="form-validation-errors"
                  *ngIf="
                    billingCity.invalid &&
                    (billingCity.dirty || billingCity.touched)
                  "
                >
                  <ng-container
                    *ngFor="let validation of validation_messages.billing_city"
                  >
                    <div
                      class="form-input-error-message"
                      *ngIf="billingCity.hasError(validation.type)"
                    >
                      {{ validation.message }}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="form-group col-md-4">
                <label>Postcode</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="billing_postcode"
                />
                <div
                  class="form-validation-errors"
                  *ngIf="
                    billingPostcode.invalid &&
                    (billingPostcode.dirty || billingPostcode.touched)
                  "
                >
                  <ng-container
                    *ngFor="
                      let validation of validation_messages.billing_postcode
                    "
                  >
                    <div
                      class="form-input-error-message"
                      *ngIf="billingPostcode.hasError(validation.type)"
                    >
                      {{ validation.message }}
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset *ngIf="subscription.acceptedPayments.indexOf('card') > -1">
            <label>Payment Details</label>
            <div class="form-group">
              <label>Name as it appears on card</label>
              <input
                type="text"
                class="form-control"
                formControlName="billingCardName"
              />
              <div
                class="form-validation-errors"
                *ngIf="
                  billingCardName.invalid &&
                  (billingCardName.dirty || billingCardName.touched)
                "
              >
                <ng-container
                  *ngFor="
                    let validation of validation_messages.billing_card_name
                  "
                >
                  <div
                    class="form-input-error-message"
                    *ngIf="billingCardName.hasError(validation.type)"
                  >
                    {{ validation.message }}
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="form-group">
              <ngx-stripe-card
                #card
                [options]="cardOptions"
                [elementsOptions]="elementsOptions"
              ></ngx-stripe-card>
            </div>
          </fieldset>

          <fieldset
            *ngIf="subscription.acceptedPayments.indexOf('invoice') > -1"
          >
            <label>Payment Details</label>
            <div class="form-group">
              <label>PO Number</label>
              <input
                type="text"
                class="form-control"
                formControlName="po_number"
              />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>

  <div class="col-md-4 col-sm-12">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Your Upgrade</h5>
            <table class="table table-striped">
              <tbody>
                <tr>
                  <th scope="row">Subscription</th>
                  <td>{{ subscription.label }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    Credits <br /><small>(Candidate Connections)</small>
                  </th>
                  <td>{{ subscription.credits }}</td>
                </tr>
                <tr *ngIf="discountApplied">
                  <th scope="row">Discount</th>
                  <td>{{ discountApplied }}%</td>
                </tr>
                <tr>
                  <th scope="row">Total to pay</th>
                  <td>{{ subscription.price | ttCurrency }}</td>
                </tr>
              </tbody>
            </table>

            <button
              *ngIf="subscription.acceptedPayments.indexOf('invoice') > -1"
              class="btn btn-block btn-primary m-t-lg"
              (click)="requestInvoice()"
            >
              Request Invoice
            </button>
            <button
              *ngIf="subscription.acceptedPayments.indexOf('card') > -1"
              class="btn btn-block btn-primary m-t-lg"
              [disabled]="this.isPaymentProcessing"
              (click)="processPayment()"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                *ngIf="isPaymentProcessing"
              ></span>
              Pay
            </button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Have a discount code?</h5>
            <p *ngIf="discountApplied">
              You applied discount code {{ discountCode.value }}
            </p>
            <form [formGroup]="discountCodeForm" *ngIf="!discountApplied">
              <div class="form-group">
                <label>Discount code</label>
                <input
                  formControlName="discount_code"
                  type="text"
                  class="form-control"
                />

                <div
                  class="form-validation-errors"
                  *ngIf="
                    discountCode.invalid &&
                    (discountCode.dirty || discountCode.touched)
                  "
                >
                  <ng-container
                    *ngFor="let validation of validation_messages.discount_code"
                  >
                    <div
                      class="form-input-error-message"
                      *ngIf="discountCode.hasError(validation.type)"
                    >
                      {{ validation.message }}
                    </div>
                  </ng-container>
                </div>
              </div>
            </form>
            <button
              class="btn btn-block btn-primary m-t-lg"
              (click)="processDiscount()"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
