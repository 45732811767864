<div class="login-page">
  <div class="container">
    <div class="login-container">
      <div class="row">
        <div class="col-lg-4 col-md-5 col-sm-9 lfh">
          <div class="card login-box">
            <div class="card-body">
              <h5 class="card-title">Reset your password</h5>

              <div class="alert alert-danger" *ngIf="errorMsg">
                {{ errorMsg }}
              </div>

              <div class="alert alert-success" *ngIf="success">
                {{ success }}
              </div>

              <form
                *ngIf="!success"
                [formGroup]="loginFormGroup"
                (ngSubmit)="onSubmit(loginFormGroup.value)"
              >
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    formControlName="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  />
                </div>

                <button
                  type="submit"
                  class="btn btn-primary float-right m-l-xxs"
                >
                  Reset
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
