export class Address {
  line1: String;
  line2: String;
  postcode: String;
  city: String;
  country: String;

  constructor() {
    this.line1 = "";
    this.line2 = "";
    this.postcode = "";
    this.city = "";
    this.country = "";
  }

  toJson() {
    return {
      line1: this.line1 || "",
      line2: this.line2 || "",
      postcode: this.postcode || "",
      city: this.city || "",
      country: this.country || "",
    };
  }
}
