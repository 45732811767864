import { User } from './../../tt-models/user.model';
import { AuthService } from './../../tt-services/auth/auth.service';
import { SubscriptionItem } from './../../tt-models/subscription-item.model';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, take } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { PaymentService } from 'src/app/tt-services/payment/payment.service';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import csc from 'country-state-city';
@Component({
  selector: 'app-upgrade-payment',
  templateUrl: './upgrade-payment.component.html',
  styleUrls: ['./upgrade-payment.component.scss'],
})
export class UpgradePaymentComponent implements OnInit {
  errorMsg = null;

  @ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    classes: {
      base: 'card-info-base',
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  billingForm: FormGroup;
  discountCodeForm: FormGroup;
  subscription: SubscriptionItem;
  currentUser: User;

  ready = false;
  isPaymentProcessing = false;

  validation_messages = {
    billing_firstname: [
      { type: 'required', message: 'First name is required.' },
    ],
    billing_lastname: [{ type: 'required', message: 'Last name is required.' }],
    billing_address1: [{ type: 'required', message: 'Address is required' }],
    billing_city: [{ type: 'required', message: 'City is required' }],
    billing_postcode: [
      { type: 'required', message: 'Postcode is required' },
      {
        type: 'pattern',
        message: 'You entered an invalid postcode.',
      },
    ],
    billing_card_name: [
      { type: 'required', message: 'Payment card name is required' },
    ],
    discount_code: [
      { type: 'required', message: 'Discount code is required' },
      { type: 'invalidDiscountCode', message: 'Discount code is invalid' },
    ],
  };

  postcodePattern =
    '^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$';

  discountApplied = null;
  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.errorMsg = null;
    this.isPaymentProcessing = false;
    this.ready = false;

    let subscriptionId = this.route.snapshot.params.id;

    this.currentUser = this.authService.currentUser;

    const cityName = csc.getCityById(this.currentUser.address.city.toString());
    this.billingForm = this.fb.group({
      billingCardName: ['', [Validators.required]],
      billing_firstname: [this.currentUser.firstname, [Validators.required]],
      billing_lastname: [this.currentUser.lastname, [Validators.required]],
      billing_address1: [this.currentUser.address.line1, [Validators.required]],
      billing_address2: [this.currentUser.address.line2],
      billing_city: [cityName ? cityName.name : '', [Validators.required]],
      billing_postcode: [
        this.currentUser.address.postcode,
        [Validators.required, Validators.pattern(this.postcodePattern)],
      ],
      po_number: [''],
    });

    this.discountCodeForm = this.fb.group({
      discount_code: ['', Validators.required],
    });

    this.paymentService
      .getSubscription(subscriptionId)
      .pipe(take(1))
      .subscribe((subscription) => {
        this.subscription = subscription;
        this.ready = true;
      });
  }

  ngAfterViewInit() {}

  requestInvoice() {}

  invalidDiscountCodeValidator(c: FormControl) {
    return {
      invalidDiscountCode: {
        valid: false,
      },
    };
  }

  async processDiscount() {
    this.discountCode.setValidators([Validators.required]);
    this.discountCode.updateValueAndValidity();
    if (!this.discountCodeForm.valid) {
      Object.keys(this.discountCodeForm.controls).forEach((field) => {
        // {1}
        const control = this.discountCodeForm.get(field); // {2}
        control.markAsTouched({ onlySelf: true }); // {3}
      });
      this.discountCodeForm.updateValueAndValidity();
      return;
    }

    //Get Discount

    const percentage = await this.paymentService.getDiscountCodePercentage(
      this.discountCode.value
    );

    if (percentage != null && !isNaN(percentage)) {
      this.subscription.price -= this.subscription.price * (percentage / 100);
      this.discountApplied = percentage;
    } else {
      this.discountCode.setValidators([
        Validators.required,
        this.invalidDiscountCodeValidator,
      ]);
      this.discountCode.updateValueAndValidity();
    }
  }

  async processPayment() {
    if (this.isPaymentProcessing) return;

    if (!this.billingForm.valid) {
      Object.keys(this.billingForm.controls).forEach((field) => {
        // {1}
        const control = this.billingForm.get(field); // {2}
        control.markAsTouched({ onlySelf: true }); // {3}
      });
      this.billingForm.updateValueAndValidity();
      return;
    }

    this.errorMsg = null;
    const name = this.billingCardName.value;
    const amount = Math.trunc(this.subscription.price * 100);
    this.isPaymentProcessing = true;
    if (this.billingForm.valid) {
      try {
        if (amount >= 1) {
          const paymentIntent = await this.paymentService
            .createPaymentIntent(amount, this.currentUser.email)
            .toPromise();
          const paymentConfirmation = await this.stripeService
            .confirmCardPayment(paymentIntent.client_secret, {
              payment_method: {
                card: this.card.element,
                billing_details: {
                  email: this.currentUser.email.toString(),
                  name: this.billingCardName.value,
                },
              },
            })
            .toPromise();
          if (paymentConfirmation.error) {
            // Show error to your customer (e.g., insufficient funds)
            this.errorMsg = paymentConfirmation.error.message;
          } else {
            // The payment has been processed!
            if (paymentConfirmation.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer
              await this.paymentService
                .confirmSubscription(this.subscription, this.currentUser.id)
                .toPromise();

              await Swal.fire(
                'Confirmed',
                'Your subscription is now active',
                'success'
              );
              // this.router.navigate(['/']);
              window.location.href = '/';
            }
          }
        } else {
          // Show a success message to your customer
          await this.paymentService
            .confirmSubscription(this.subscription, this.currentUser.id)
            .toPromise();

          await Swal.fire(
            'Confirmed',
            'Your subscription is now active',
            'success'
          );
          // this.rou
        }
      } catch (e) {
        this.isPaymentProcessing = false;
        this.errorMsg =
          'Failed to process your payment. Please try again. If the problem persists, please contact our team.';
      } finally {
        this.isPaymentProcessing = false;
      }
    }
  }

  get billingFirstname() {
    return this.billingForm.get('billing_firstname');
  }

  get billingLastname() {
    return this.billingForm.get('billing_firstname');
  }

  get billingAddress1() {
    return this.billingForm.get('billing_address1');
  }

  get billingCity() {
    return this.billingForm.get('billing_city');
  }

  get billingPostcode() {
    return this.billingForm.get('billing_postcode');
  }

  get billingCardName() {
    return this.billingForm.get('billingCardName');
  }

  get discountCode() {
    return this.discountCodeForm.get('discount_code');
  }
}
