import { Pipe, PipeTransform } from "@angular/core";
import { isDate } from "util";
import { formatDate } from "@angular/common";

@Pipe({
  name: "invitationDatetime",
})
export class InvitationDatetimePipe implements PipeTransform {
  transform(value: Date, ...args: any[]): any {
    if (isDate(value)) {
      return formatDate(value, "MMM d, y, h:mm:ss a", "en-US");
    } else {
      return "";
    }
  }
}
