<div class="login-page">
  <div class="container">
    <div class="login-container">
      <div class="row">
        <div class="col-lg-4 col-md-5 col-sm-9 lfh">
          <div class="card login-box">
            <div class="card-body">
              <h5 class="card-title">Sign In</h5>
              <div class="alert alert-danger" *ngIf="errorMsg">
                {{ errorMsg }}
              </div>
              <form
                [formGroup]="loginFormGroup"
                (ngSubmit)="onSubmit(loginFormGroup.value)"
              >
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    formControlName="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    formControlName="password"
                    placeholder="Password"
                  />
                </div>
                <!-- <a href="#" class="float-left forgot-link">Forgot password?</a> -->

                <button
                  type="submit"
                  class="btn btn-primary float-right m-l-xxs"
                >
                  Sign In
                </button>
                <a
                  [routerLink]="['/register']"
                  class="btn btn-secondary float-right m-l-xxs"
                >
                  Sign up
                </a>
                <a [routerLink]="['/forgotten-password']">Forgotten Password</a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
