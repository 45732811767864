<div class="login-page">
  <div class="container">
    <div class="login-container">
      <div class="row">
        <div class="col-lg-8 col-md-8 col-sm-9 lfh">
          <div class="alert alert-danger" *ngIf="errorMsg">
            {{ errorMsg }}
          </div>
          <div class="card login-box" *ngIf="step == 'profile_setup'">
            <div class="card-body">
              <h5 class="card-title">Tell us about your business</h5>
              <form [formGroup]="companyProfileFormGroup" class="row">
                <div class="col-md-6">
                  <div>
                    <div class="form-group">
                      <label>Business Name <small>(required)</small></label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="company_name"
                        aria-describedby="emailHelp"
                        placeholder="Enter your business's name"
                      />
                      <div
                        class="form-validation-errors"
                        *ngIf="
                          companyName.invalid &&
                          (companyName.dirty || companyName.touched)
                        "
                      >
                        <ng-container
                          *ngFor="
                            let validation of validation_messages.companyName
                          "
                        >
                          <div
                            class="form-input-error-message"
                            *ngIf="companyName.hasError(validation.type)"
                          >
                            {{ validation.message }}
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Company Number <small>(required)</small></label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="company_number"
                        aria-describedby="emailHelp"
                        placeholder="Enter your company number"
                      />
                      <div
                        class="form-validation-errors"
                        *ngIf="
                          companyNumber.invalid &&
                          (companyNumber.dirty || companyNumber.touched)
                        "
                      >
                        <ng-container
                          *ngFor="
                            let validation of validation_messages.companyNumber
                          "
                        >
                          <div
                            class="form-input-error-message"
                            *ngIf="companyNumber.hasError(validation.type)"
                          >
                            {{ validation.message }}
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12">
                      <label
                        >Registered Address <small>(required)</small></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        formControlName="address_1"
                        aria-describedby="emailHelp"
                        placeholder="Line 1"
                      />
                      <div
                        class="form-validation-errors"
                        *ngIf="
                          address1.invalid &&
                          (address1.dirty || address1.touched)
                        "
                      >
                        <ng-container
                          *ngFor="
                            let validation of validation_messages.address_1
                          "
                        >
                          <div
                            class="form-input-error-message"
                            *ngIf="address1.hasError(validation.type)"
                          >
                            {{ validation.message }}
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="form-group col-12">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="address_2"
                        aria-describedby="emailHelp"
                        placeholder="Line 2"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <select
                        formControlName="address_city"
                        class="form-control custom-select"
                      >
                        <option selected disabled [value]="null">
                          Select city
                        </option>
                        <option
                          [value]="city.id"
                          *ngFor="let city of allCities"
                        >
                          {{ city.name }}
                        </option>
                      </select>
                      <div
                        class="form-validation-errors"
                        *ngIf="
                          addressCity.invalid &&
                          (addressCity.dirty || addressCity.touched)
                        "
                      >
                        <ng-container
                          *ngFor="
                            let validation of validation_messages.address_city
                          "
                        >
                          <div
                            class="form-input-error-message"
                            *ngIf="addressCity.hasError(validation.type)"
                          >
                            {{ validation.message }}
                          </div>
                        </ng-container>
                      </div>
                    </div>

                    <div class="form-group col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="address_postcode"
                        aria-describedby="emailHelp"
                        placeholder="Postcode"
                      />
                      <div
                        class="form-validation-errors"
                        *ngIf="
                          addressPostcode.invalid &&
                          (addressPostcode.dirty || addressPostcode.touched)
                        "
                      >
                        <ng-container
                          *ngFor="
                            let validation of validation_messages.address_postcode
                          "
                        >
                          <div
                            class="form-input-error-message"
                            *ngIf="addressPostcode.hasError(validation.type)"
                          >
                            {{ validation.message }}
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Telephone number <small>(required)</small></label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="company_telephone"
                      aria-describedby="emailHelp"
                      placeholder="Enter telephone number"
                    />
                    <div
                      class="form-validation-errors"
                      *ngIf="
                        telephoneNumber.invalid &&
                        (telephoneNumber.dirty || telephoneNumber.touched)
                      "
                    >
                      <ng-container
                        *ngFor="
                          let validation of validation_messages.company_telephone
                        "
                      >
                        <div
                          class="form-input-error-message"
                          *ngIf="telephoneNumber.hasError(validation.type)"
                        >
                          {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Website <small>(required)</small></label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="company_website"
                      aria-describedby="emailHelp"
                      placeholder="Enter website"
                    />
                    <div
                      class="form-validation-errors"
                      *ngIf="
                        website.invalid && (website.dirty || website.touched)
                      "
                    >
                      <ng-container
                        *ngFor="
                          let validation of validation_messages.company_website
                        "
                      >
                        <div
                          class="form-input-error-message"
                          *ngIf="website.hasError(validation.type)"
                        >
                          {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Size <small>(required)</small></label>
                    <select
                      formControlName="company_size"
                      class="form-control custom-select"
                    >
                      <option selected disabled [value]="null">
                        Select size
                      </option>
                      <option value="micro">
                        Micro (less than 10 employees)
                      </option>
                      <option value="small">Small (10 to 49 employees)</option>
                      <option value="medium">
                        Medium (50 to 249 employees)
                      </option>
                      <option value="large">Large (250 or more)</option>
                    </select>
                    <div
                      class="form-validation-errors"
                      *ngIf="
                        companySize.invalid &&
                        (companySize.dirty || companySize.touched)
                      "
                    >
                      <ng-container
                        *ngFor="
                          let validation of validation_messages.company_size
                        "
                      >
                        <div
                          class="form-input-error-message"
                          *ngIf="companySize.hasError(validation.type)"
                        >
                          {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <!-- <div class="form-group">
                    <label>Industry</label>
                    <select
                      formControlName="company_industry"
                      class="form-control custom-select"
                    >
                      <option>IT</option>
                      <option>Consultancy</option>
                      <option>HR</option>
                    </select>
                    <div
                      class="form-validation-errors"
                      *ngIf="
                        industry.invalid && (industry.dirty || industry.touched)
                      "
                    >
                      <ng-container
                        *ngFor="
                          let validation of validation_messages.company_industry
                        "
                      >
                        <div
                          class="form-input-error-message"
                          *ngIf="industry.hasError(validation.type)"
                        >
                          {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>
                  </div> -->
                </div>
                <div class="col-12 text-center pt-4">
                  <button
                    type="submit"
                    class="btn btn-primary m-l-xxs"
                    (click)="nextStage()"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="card login-box" *ngIf="step == 'account_setup'">
            <div class="card-body">
              <h5 class="card-title">Let's setup your account access</h5>
              <form
                [formGroup]="accountFormGroup"
                (ngSubmit)="onSubmit()"
                class="row"
              >
                <div class="col-md-6">
                  <div>
                    <div class="form-group">
                      <label>First name <small>(required)</small></label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="account_firstname"
                        aria-describedby="emailHelp"
                        placeholder="Enter first name"
                      />
                      <div
                        class="form-validation-errors"
                        *ngIf="
                          firstname.invalid &&
                          (firstname.dirty || firstname.touched)
                        "
                      >
                        <ng-container
                          *ngFor="
                            let validation of validation_messages.account_firstname
                          "
                        >
                          <div
                            class="form-input-error-message"
                            *ngIf="firstname.hasError(validation.type)"
                          >
                            {{ validation.message }}
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Last name <small>(required)</small></label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="account_lastname"
                        aria-describedby="emailHelp"
                        placeholder="Enter your last name"
                      />
                      <div
                        class="form-validation-errors"
                        *ngIf="
                          lastname.invalid &&
                          (lastname.dirty || lastname.touched)
                        "
                      >
                        <ng-container
                          *ngFor="
                            let validation of validation_messages.account_lastname
                          "
                        >
                          <div
                            class="form-input-error-message"
                            *ngIf="lastname.hasError(validation.type)"
                          >
                            {{ validation.message }}
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12">
                      <label>Role <small>(required)</small></label>
                      <select
                        formControlName="account_role"
                        class="form-control custom-select"
                        placeholder="Select your role"
                        [value]="null"
                      >
                        <option [value]="null" selected disabled>
                          Select your role
                        </option>
                        <option value="0">Director/Owner</option>
                        <option value="1">HR Professional</option>
                        <option value="2">Line Manager</option>
                        <option value="3">Other</option>
                      </select>
                      <div
                        class="form-validation-errors"
                        *ngIf="role.invalid && (role.dirty || role.touched)"
                      >
                        <ng-container
                          *ngFor="
                            let validation of validation_messages.account_role
                          "
                        >
                          <div
                            class="form-input-error-message"
                            *ngIf="role.hasError(validation.type)"
                          >
                            {{ validation.message }}
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Company Email <small>(required)</small></label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="account_email"
                      aria-describedby="emailHelp"
                      placeholder="Enter your company email"
                    />
                    <div
                      class="form-validation-errors"
                      *ngIf="
                        accountEmail.invalid &&
                        (accountEmail.dirty || accountEmail.touched)
                      "
                    >
                      <ng-container
                        *ngFor="
                          let validation of validation_messages.account_email
                        "
                      >
                        <div
                          class="form-input-error-message"
                          *ngIf="accountEmail.hasError(validation.type)"
                        >
                          {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Password <small>(required)</small></label>
                    <input
                      type="password"
                      class="form-control"
                      formControlName="account_password"
                      aria-describedby="emailHelp"
                      placeholder="Enter a password"
                    />
                    <div
                      class="form-validation-errors"
                      *ngIf="
                        accountPassword.invalid &&
                        (accountPassword.dirty || accountPassword.touched)
                      "
                    >
                      <ng-container
                        *ngFor="
                          let validation of validation_messages.account_password
                        "
                      >
                        <div
                          class="form-input-error-message"
                          *ngIf="accountPassword.hasError(validation.type)"
                        >
                          {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Confirm Password <small>(required)</small></label>
                    <input
                      type="password"
                      class="form-control"
                      formControlName="account_password_repeat"
                      aria-describedby="emailHelp"
                      placeholder="Confirm your password"
                    />
                    <div
                      class="form-validation-errors"
                      *ngIf="
                        accountPasswordRepeat.invalid &&
                        (accountPasswordRepeat.dirty ||
                          accountPasswordRepeat.touched)
                      "
                    >
                      <ng-container
                        *ngFor="
                          let validation of validation_messages.account_password_repeat
                        "
                      >
                        <div
                          class="form-input-error-message"
                          *ngIf="
                            accountPasswordRepeat.hasError(validation.type)
                          "
                        >
                          {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-12 text-center pt-4">
                  <ttspinner *ngIf="procesing"></ttspinner>
                  <button
                    type="submit"
                    class="btn btn-primary m-l-xxs"
                    *ngIf="!procesing"
                  >
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
