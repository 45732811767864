import { SubscriptionItem } from "./../../tt-models/subscription-item.model";
import { AngularFirestore } from "@angular/fire/firestore";
import { UserService } from "./../user/user.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireFunctions } from "@angular/fire/functions";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(
    public auth: AngularFireAuth,
    private userService: UserService,
    private fs: AngularFirestore,
    private fbf: AngularFireFunctions
  ) {}

  async getDiscountCodePercentage(discountCode: String) {
    const discountCodeQuery = await this.fs
      .collection("global/static/discount_codes", (qn) => {
        return qn.where("label", "==", discountCode.toUpperCase());
      })
      .get()
      .toPromise();

    if (!discountCodeQuery.empty) {
      const discountCodeDoc = discountCodeQuery.docs[0];
      const discountCode = discountCodeDoc.data();

      return discountCode.percentage;
    }

    return null;
  }

  getSubscription(id) {
    const subscriptionsCollection = this.fs.collection("subscriptions");
    return subscriptionsCollection
      .doc(id)
      .get()
      .pipe(
        map((docSnap) => {
          if (docSnap.exists) {
            const data = docSnap.data();

            const subscriptionItem = SubscriptionItem.createSubscriptionItem(
              data.id,
              data
            );
            return subscriptionItem;
          }
        })
      );
  }

  createPaymentIntent(amount, email) {
    const callable = this.fbf.httpsCallable("createPaymentIntent");
    return callable({ amount, email });
  }

  confirmSubscription(subscription: SubscriptionItem, userId) {
    const users = this.fs.collection("users");
    return users
      .doc(userId)
      .get()
      .pipe(
        map((docSnap) => {
          if (docSnap.exists) {
            return of(
              docSnap.ref.update({
                subscription: {
                  label: subscription.label,
                  name: subscription.name,
                  remaining_credits: subscription.credits,
                  total_credits: subscription.credits,
                },
              })
            );
          } else {
            return of(null);
          }
        })
      );
  }
}
