import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ttAvailabilityDuration',
})
export class TtAvailabilityDurationPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    switch (value) {
      case 1:
        return '1 month';
        break;
      case 2:
        return 'up to 6 months';
        break;
      case 3:
        return 'up to 12 months';
        break;
      case 4:
        return '12 months or more';
        return;
    }
  }
}
