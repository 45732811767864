export class Subscription {
  label: string;
  name: string;
  totalCredits: number;
  remainingCredits: number;
  order: number;
  date: Date;

  constructor() {
    this.label = "";
    this.name = "";
    this.totalCredits = 0;
    this.remainingCredits = 0;
    this.order = -1;
    this.date = new Date();
  }

  get remainingDays() {
    if (this.isTrial) {
      const todaysDate = new Date();
      return (
        15 -
        Math.round(
          (todaysDate.getTime() - this.date.getTime()) / (1000 * 60 * 60 * 24)
        )
      );
    }
    return null;
  }

  get isExpired() {
    return this.remainingDays && this.remainingDays <= 0;
  }

  get isTrial() {
    return this.name == "trial";
  }

  toJson() {
    return {
      label: this.label,
      name: this.name,
      total_credits: this.totalCredits,
      remaining_credits: this.remainingCredits,
    };
  }
}
