export class Qualification {
  name: string;
  id: number;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
    };
  }

  // static QUALIFICATIONS = [
  //   { id: 2456, name: "Accredited Symbian Developer" },
  //   { id: 2457, name: "ACDT, Apple Certified Desktop Technician" },
  //   { id: 2458, name: "ACE, Adobe Certified Expert" },
  //   { id: 2459, name: "ACHDS, Apple Certified Help Desk Specialist" },
  //   { id: 2460, name: "ACMA, Apple Certified Media Administrator" },
  //   { id: 2461, name: "ACMT, Apple Certified Macintosh Technician" },
  //   { id: 2462, name: "ACPT, Apple Certified Portable Technician" },
  //   { id: 2463, name: "ACSA, Apple Certified System Administrator" },
  //   { id: 2464, name: "ACT, Apple Certified Trainer" },
  //   { id: 2465, name: "ACTC, Apple Certified Technical Coordinator" },
  //   { id: 2466, name: "Adobe Certified Expert" },
  //   {
  //     id: 2467,
  //     name:
  //       "APM CPM, Association for Project Management Certified Project Manager",
  //   },
  //   { id: 2468, name: "APMP, Association for Project Management" },
  //   {
  //     id: 2469,
  //     name:
  //       "APM PQ, Association for Project Management Practitioner Qualification",
  //   },
  //   { id: 2470, name: "Apple certification programs" },
  //   { id: 2471, name: "Apple Certified System Administrator" },
  //   { id: 2472, name: "ARM Accredited Engineer" },
  //   { id: 2473, name: "AWS, Amazon Web Services Architect and DevOps" },
  //   { id: 2474, name: "Bachelor of Science in Information Technology" },
  //   { id: 2475, name: "BCS Higher Education Qualifications" },
  //   { id: 2476, name: "BCS Professional Examinations" },
  //   { id: 2477, name: "Cambridge IT Skills Diploma" },
  //   { id: 2478, name: "CBAP, Certified Business Analysis Professional" },
  //   { id: 2479, name: "CCA, Citrix Certified Administrator" },
  //   { id: 2480, name: "CCDA, Cisco Certified Design Associate" },
  //   { id: 2481, name: "CCDP, Cisco Certified Design Professional" },
  //   { id: 2482, name: "CCEA, Citrix Certified Enterprise Administrator" },
  //   { id: 2483, name: "CCENT, Cisco Certified Entry Networking Technician" },
  //   { id: 2484, name: "CCIE, Cisco Certified Internetwork Expert" },
  //   { id: 2485, name: "CCIP, Cisco Certified Internetwork Professional" },
  //   { id: 2486, name: "CCMA, Check Point Certified Master Architect" },
  //   { id: 2487, name: "CCNA, Cisco Certified Network Associate" },
  //   { id: 2488, name: "CCNP, Cisco Certified Network Professional" },
  //   { id: 2489, name: "CCSA, Check Point Certified Security Administrator" },
  //   { id: 2490, name: "CCSE, Check Point Certified Security Expert" },
  //   { id: 2491, name: "CCSP, Cisco Certified Security Professional" },
  //   { id: 2492, name: "CCVP, Cisco Certified Voice Professional" },
  //   { id: 2493, name: "CEH, Certified Ethical Hacker" },
  //   { id: 2494, name: "Certificate in Data Processing" },
  //   { id: 2495, name: "Certified Ethical Hacker" },
  //   { id: 2496, name: "Certified Forensic Computer Examiner" },
  //   { id: 2497, name: "Certified Information Systems Security Professional" },
  //   { id: 2498, name: "Certified Information Technology Professional" },
  //   { id: 2499, name: "Certified Internet Web Professional" },
  //   { id: 2500, name: "Certified Penetration Testing Engineer" },
  //   { id: 2501, name: "Certified Social Engineering Prevention Specialist" },
  //   { id: 2502, name: "Certified Software Development Professional" },
  //   { id: 2503, name: "Certified software manager" },
  //   { id: 2504, name: "Certified Wireless Network Administrator" },
  //   { id: 2505, name: "Certified Wireless Network Expert" },
  //   { id: 2506, name: "Certified Wireless Security Professional" },
  //   { id: 2507, name: "CF, ColdFusion Certified Developer" },
  //   { id: 2508, name: "CGEIT, Certified in the Governance of Enterprise IT" },
  //   { id: 2509, name: "Chartered IT Professional" },
  //   { id: 2510, name: "CHFI, Certified Hacking Forensic Investigator" },
  //   { id: 2511, name: "CISA, Certified Information Systems Auditor" },
  //   { id: 2512, name: "Cisco certifications" },
  //   { id: 2513, name: "Cisco Certified Entry Networking Technician" },
  //   { id: 2514, name: "CISM, Certified Information Security Manager" },
  //   {
  //     id: 2515,
  //     name: "CISMP, Certificate in Information Security Management Principles",
  //   },
  //   {
  //     id: 2516,
  //     name: "CISSP, Certified Information Systems Security Professional",
  //   },
  //   { id: 2517, name: "CITP, Chartered IT Professional (BCS)" },
  //   { id: 2518, name: "Citrix Technology Professional" },
  //   { id: 2519, name: "CNA, Certified Novell Administrator" },
  //   { id: 2520, name: "CNE, Certified Novell Engineer" },
  //   { id: 2521, name: "CompTIA A+, Computing Technology Industry Association" },
  //   {
  //     id: 2522,
  //     name: "CompTIA Network+, Computing Technology Industry Association",
  //   },
  //   {
  //     id: 2523,
  //     name: "CompTIA Security+, Computing Technology Industry Association",
  //   },
  //   { id: 2524, name: "CPP, Certified Protection Professional" },
  //   {
  //     id: 2525,
  //     name: "CRISC, Certified in Risk and Information Systems Control",
  //   },
  //   {
  //     id: 2526,
  //     name: "CSSLP, Certified Secure Software Lifecycle Professional",
  //   },
  //   { id: 2527, name: "CSX, Cybersecurity Nexus Certificate" },
  //   { id: 2528, name: "CWNA, Certified Wireless Network Administrator" },
  //   { id: 2529, name: "Degree in Computing, Degree in Computing" },
  //   { id: 2530, name: "Diploma in Digital Applications (DiDA)" },
  //   { id: 2531, name: "Doctor of Computer Science" },
  //   { id: 2532, name: "Doctor of Information Technology" },
  //   { id: 2533, name: "E-Pro (certification)" },
  //   { id: 2534, name: "EC-Council" },
  //   { id: 2535, name: "ECDL, European Computer Driving Licence" },
  //   { id: 2536, name: "ELearnSecurity" },
  //   { id: 2537, name: "European Computer Driving Licence" },
  //   { id: 2538, name: "European Information Technologies Certification" },
  //   {
  //     id: 2539,
  //     name: "European Information Technologies Certification Academy",
  //   },
  //   { id: 2540, name: "GCED, GIAC Certified Enterprise Defender" },
  //   { id: 2541, name: "GCIA, GIAC Certified Intrusion Analyst" },
  //   { id: 2542, name: "GCIH, GIAC Certified Incident Handler" },
  //   { id: 2543, name: "GPEN, GIAC Penetration Tester" },
  //   { id: 2544, name: "GPPA, GIAC Certified Perimeter Protection Analyst" },
  //   { id: 2545, name: "GSNA, GIAC Systems and Network Auditor" },
  //   { id: 2546, name: "GWAPT, GIAC Web Application Penetration Tester" },
  //   { id: 2547, name: "IC3 (certification)" },
  //   { id: 2548, name: "IIBA, International Institute of Business Analysis" },
  //   { id: 2549, name: "IISP, Institute of Information Security Professionals" },
  //   { id: 2550, name: "Information-Technology Engineers Examination" },
  //   { id: 2551, name: "Information Systems Professional" },
  //   { id: 2552, name: "Information Technology Architect Certification" },
  //   {
  //     id: 2553,
  //     name: "Information Technology Professional Examination Council",
  //   },
  //   {
  //     id: 2554,
  //     name:
  //       "International Information and Communication Technology Council Certification Program",
  //   },
  //   { id: 2555, name: "ISEB, Information Systems Examination Board" },
  //   { id: 2556, name: "ISO/IEC 27001 Lead Implementer" },
  //   {
  //     id: 2557,
  //     name: "ISSAP, Information Systems Security Architecture Professional",
  //   },
  //   {
  //     id: 2558,
  //     name: "ISTQB, International Software Testing Qualifications Board",
  //   },
  //   {
  //     id: 2559,
  //     name: "ITIL Certification, Information Technology Infrastructure Library",
  //   },
  //   { id: 2560, name: "JNCIA, Juniper Networks Certified Associate" },
  //   { id: 2561, name: "Linux Foundation Linux Certification" },
  //   { id: 2562, name: "Linux Professional Institute" },
  //   { id: 2563, name: "Linux Professional Institute Certification Programs" },
  //   { id: 2564, name: "List of computer security certifications" },
  //   { id: 2565, name: "LPIC, Linux Professional Institute Certification" },
  //   { id: 2566, name: "Master of Information System Management" },
  //   { id: 2567, name: "Master of IT in Business" },
  //   { id: 2568, name: "Master of Science in Information Systems" },
  //   { id: 2569, name: "Master of Science in Information Technology" },
  //   { id: 2570, name: "MCAD, Microsoft Certified Application Developer" },
  //   { id: 2571, name: "MCDBA, Microsoft Certified Database Administrator" },
  //   { id: 2572, name: "MCDST, Microsoft Certified Desktop Support Technician" },
  //   { id: 2573, name: "MCITP, Microsoft Certified IT Professional" },
  //   { id: 2574, name: "MCP, Microsoft Certified Professional" },
  //   { id: 2575, name: "MCPD, Microsoft Certified Professional Developer" },
  //   { id: 2576, name: "MCSA, Microsoft Certified Systems Administrator" },
  //   { id: 2577, name: "MCSD, Microsoft Certified Solutions Developer" },
  //   { id: 2578, name: "MCSE, Microsoft Certified Server Engineer" },
  //   { id: 2579, name: "MCT, Microsoft Certified Trainer" },
  //   { id: 2580, name: "MCTS, Microsoft Certified Technology Specialist" },
  //   { id: 2581, name: "Microsoft Certified Professional" },
  //   { id: 2582, name: "MOS, Microsoft Office Specialist" },
  //   { id: 2583, name: "NCDA, NetApp Certified Data Administrator" },
  //   { id: 2584, name: "NCIE, NetApp Certified Implementation Engineer" },
  //   { id: 2585, name: "Nortel Certifications" },
  //   { id: 2586, name: "OCA, Oracle Certified Database Analyst" },
  //   { id: 2587, name: "OCMJEA, Oracle Certified Master" },
  //   { id: 2588, name: "OCP, Oracle Certified Professional" },
  //   { id: 2589, name: "Offensive Security Certified Professional" },
  //   { id: 2590, name: "Oracle Certification Program" },
  //   { id: 2591, name: "Organizational Systems Security Analyst" },
  //   { id: 2592, name: "OSCP, Offensive Security Certified Professional" },
  //   { id: 2593, name: "PCI QSA, Qualified Security Assessor" },
  //   { id: 2594, name: "PgMP, Program Management Professional" },
  //   { id: 2595, name: "PhilNITS" },
  //   { id: 2596, name: "PMI, Project Management Institute" },
  //   { id: 2597, name: "PMP, Project Management Professional" },
  //   { id: 2598, name: "Prince2, PRojects In Controlled Environments" },
  //   { id: 2599, name: "Professional certification (computer technology)" },
  //   { id: 2600, name: "QiCA, IIA Qualification in Computer Auditing" },
  //   { id: 2601, name: "Red Hat Certification Program" },
  //   { id: 2602, name: "RegTech technician registration" },
  //   { id: 2603, name: "RHCA, Red Hat Certified Architect" },
  //   { id: 2604, name: "RHCE, Red Hat Certified Engineer" },
  //   { id: 2605, name: "RHCSA, Red Hat Certified System Administrator" },
  //   { id: 2606, name: "RHCT, Red Hat Certified Technician" },
  //   { id: 2607, name: "SCBCD, Sun Certified Business Component Developer" },
  //   { id: 2608, name: "SCDJWS, Sun Certified Developer for Java Web Services" },
  //   { id: 2609, name: "SCJA, Sun Certified Java Associate" },
  //   { id: 2610, name: "SCJD, Sun Certified Java Developer" },
  //   { id: 2611, name: "SCNA, Sun Certified Network Administrator" },
  //   { id: 2612, name: "SCSA, Sun Certified System Administrator" },
  //   { id: 2613, name: "SDI, Service Desk Institute" },
  //   { id: 2614, name: "SSCP, Systems Security Certified Practitioner" },
  //   { id: 2615, name: "Storage Networking Certification Program" },
  //   { id: 2616, name: "Strata Exam" },
  //   { id: 2617, name: "Sun Certified Network Administrator" },
  //   { id: 2618, name: "TOPCIT" },
  //   { id: 2619, name: "Ubuntu Professional Certification" },
  //   { id: 2620, name: "VCAP, VMware Certified Advanced Professional" },
  //   { id: 2621, name: "VCDX, VMware Certified Design Expert" },
  //   { id: 2622, name: "VCP, VERITAS Certified Professional" },
  //   { id: 2623, name: "VCP, VMware Certified Professional" },
  //   { id: 2624, name: "WCAG, Web Content Accessibility Guidelines" },
  //   { id: 2625, name: "XML Certification Program" },
  // ];
}
