import { StaticService } from './tt-services/static/static.service';
import { Component } from '@angular/core';
import * as LogRocket from 'logrocket';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'test';
  loaded = false;

  constructor(
    private staticService: StaticService,
    private router: Router,
    private modalService: NgbModal
  ) {
    LogRocket.init('talent-toute/talent-toute-business-app');
    this.init();

    this.router.events.subscribe((event) => {
      this.modalService.dismissAll();
    });
  }

  async init() {
    await this.staticService.loadData();
    this.loaded = true;
  }
}
