import { Candidate } from "./candidate.model";
import { Business } from "./business.model";
export class Conversation {
  id: String;
  business: Business;
  candidate: Candidate;
  lastMessage: String;
  lastMessageDate: Date;
  lastMessageFrom: String;
  status: Number;
  lastReads: {};
  type: Number;

  get isEmployerChat() {
    return this.type == ConversationType.EMPLOYER_CHAT;
  }

  get isVerificationChat() {
    return this.type == ConversationType.VERIFICATION_CHAT;
  }

  get isTTChat() {
    return this.type == ConversationType.TT_CHAT;
  }

  get isHired() {
    return this.status == ConversationStatus.HIRED;
  }

  constructor() {
    this.id = "";
    this.type = 0;
    this.business = new Business();
    this.candidate = new Candidate();
    this.lastMessage = "";
    this.lastMessageDate = new Date();
    this.status = -1;
    this.lastReads = {};
    this.type = ConversationType.EMPLOYER_CHAT;
  }

  // static createTextMessage(senderUID: any, text: string) {
  //   const content = new ConversationMessageContentText(text);
  //   return this.create(senderUID, content, ConversationMessageType.Text);
  // }

  // static createPhotoMessage(senderUID: any, photoURI: string) {
  //   const content = new ConversationMessageContentPhoto({ photo: photoURI });
  //   return this.create(senderUID, content, ConversationMessageType.Photo);
  // }

  // static createDocumentMessage(
  //   senderUID: any,
  //   documentURL: string,
  //   name: string,
  //   type: string
  // ) {
  //   const content = new ConversationMessageContentDocument({
  //     document: documentURL,
  //     name,
  //     type,
  //   });
  //   return this.create(senderUID, content, ConversationMessageType.Document);
  // }
}

export enum ConversationType {
  EMPLOYER_CHAT = 0,
  TT_CHAT = 1,
  VERIFICATION_CHAT = 2,
}

export enum ConversationStatus {
  OPENED = 0,
  CLOSED = 1,
  HIRED = 3,
}
