<div class="lime-header" *ngIf="currentUserObserver | async as currentUser">
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand" href="#" [routerLink]="['/']"
        ><img src="assets/images/logo_horizontal.svg"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="toggleMobileMenu()"
      >
        <i class="fas fa-bars"></i>
      </button>

      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        [class.show]="showMobileMenu"
      >
        <ul class="navbar-nav ml-auto">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle theme-settings-link"
              [routerLink]="['dashboard']"
            >
              Dashboard
            </a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle theme-settings-link"
              [routerLink]="['find']"
            >
              Find Candidates
            </a>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              (click)="resetUnread()"
              [routerLink]="['messages']"
            >
              Messages
              <span
                class="badge badge-success"
                *ngIf="unreadMessageCount > 0"
                >{{ unreadMessageCount }}</span
              >
            </a>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              [routerLink]="['projects']"
            >
              Projects
            </a>
          </li>

          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Favourite Candidates
            </a>
          </li> -->
          <li class="nav-item" ngbDropdown>
            <a
              id="dropdownBasic2"
              role="button"
              class="nav-link text-primary"
              ngbDropdownToggle
            >
              <i class="fas fa-user-circle"></i>
              <strong>&nbsp; {{ businessUser.profile.name }}</strong>
            </a>

            <ul ngbDropdownMenu aria-labelledby="dropdownBasic2">
              <li ngbDropdownItem>
                <a [routerLink]="['account']" href="#">Account</a>
              </li>
              <!-- <li ngbDropdownItem>
                <a [routerLink]="['settings']" ngbDropdownItem href="#"
                  >Settings</a
                >
              </li> -->
              <li class="divider"></li>
              <li ngbDropdownItem>
                <a (click)="logout()" ngbDropdownItem href="#">Log Out</a>
              </li>
            </ul>
          </li>
          <li
            style="
              width: 230px;
              padding-left: 20px;
              border-left: 1px solid #e1e1e1;
            "
          >
            <ng-container
              *ngIf="this.businessUser.subscription.name == 'trial'"
            >
              <ng-container *ngIf="this.businessUser.subscription.isExpired">
                <p>Free Trial expired</p>
                <button
                  class="btn btn-primary btn-xs"
                  [routerLink]="['/upgrade']"
                >
                  Upgrade Now
                </button>
              </ng-container>
              <ng-container *ngIf="!this.businessUser.subscription.isExpired">
                <p class="float-right font-weight-bold">
                  <span class="text-primary" *ngIf="remainingTrialDays > 0">{{
                    remainingTrialDays
                  }}</span>
                  days
                  <span class="text-primary" *ngIf="remainingTrialDays <= 0"
                    >Expired</span
                  >
                </p>
                <p>Free Trial days remaining</p>
                <progress-bar
                  *ngIf="remainingTrialDays > 0"
                  [progress]="100 - (remainingTrialDays / 15) * 100"
                ></progress-bar>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="this.businessUser.subscription.name != 'trial'"
            >
              <p class="float-right font-weight-bold">
                <span class="text-primary">{{
                  this.businessUser.subscription.remainingCredits
                }}</span
                >/{{ this.businessUser.subscription.totalCredits }}
              </p>
              <p>{{ this.businessUser.subscription.label }} | Credits left</p>
              <progress-bar
                [progress]="
                  (this.businessUser.subscription.remainingCredits /
                    this.businessUser.subscription.totalCredits) *
                  100
                "
              ></progress-bar>
            </ng-container>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>

<div class="lime-container">
  <div class="lime-body">
    <div class="container">
      <router-outlet
        *ngIf="businessUser; else currentUserLoading"
      ></router-outlet>
      <ng-template #currentUserLoading> LOADING </ng-template>
    </div>
  </div>

  <div class="lime-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <span class="footer-text text-center"
            >All Rights Reserved, 2020 &copy; Talent Toute</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
