<div class="row pb-3">
  <div class="col-12">
    <h2>Upgrade your account</h2>
    <p>
      Our packaged plans below are designed to help you get the most connections
      based on your needs and we've made it easy to upgrade at anytime. If you
      have any queries about these plan. Please get in touch with us.
    </p>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-4">
    <ul class="pricing">
      <li class="pricing-title">
        <h1>Taster</h1>
      </li>
      <li>
        <p>
          Perfect if you have minimal rercruitment needs or as a taster of
          Talent Toute has to offer
        </p>
      </li>
      <li>2 connections</li>
      <li>To be used within 2 weeks</li>

      <li class="empty">&nbsp;</li>
      <li class="empty">&nbsp;</li>
      <li>
        <h3 style="margin-top: 0" class="text-primary">&pound;299</h3>
        <h3 style="margin-top: 0"><del>&pound;495</del></h3>
        <!-- <span>per month</span> -->
      </li>
      <li>
        <button
          type="button"
          class="btn btn-primary btn-rounded"
          (click)="selectPackage('taster')"
        >
          Upgrade
        </button>
      </li>
    </ul>
  </div>

  <div class="col-12 col-md-4">
    <ul class="pricing">
      <li class="pricing-title">
        <h1>Starter</h1>
      </li>
      <li>
        <p>
          Perfect if you have minimal rercruitment needs or as a taster of
          Talent Toute has to offer
        </p>
      </li>
      <li>10 connections</li>
      <li>To be used within 6 months</li>

      <li class="empty">&nbsp;</li>
      <li class="empty">&nbsp;</li>
      <li>
        <h3 style="margin-top: 0" class="text-primary">&pound;1699</h3>
        <h3 style="margin-top: 0"><del>&pound;1999</del></h3>
        <!-- <span>per month</span> -->
      </li>
      <li>
        <button
          type="button"
          class="btn btn-primary btn-rounded"
          (click)="selectPackage('starter')"
        >
          Upgrade
        </button>
      </li>
    </ul>
  </div>

  <div class="col-12 col-md-4">
    <ul class="pricing highlighted">
      <li class="pricing-title">
        <h1>Standard</h1>
      </li>
      <li>
        <p>
          Ideal if you have a big project coming up and need to assemble a team
          within the next 6 months
        </p>
      </li>
      <li>30 connections</li>
      <li>To be used within 6 months</li>

      <li class="empty">&nbsp;</li>
      <li class="empty">&nbsp;</li>
      <li>
        <h3 style="margin-top: 0" class="text-primary">&pound;3,999</h3>
        <h3 style="margin-top: 0"><del>&pound;4,980</del></h3>
        <!-- <span>per month</span> -->
      </li>
      <li>
        <button
          type="button"
          class="btn btn-primary btn-rounded"
          (click)="selectPackage('standard')"
        >
          Upgrade
        </button>
      </li>
    </ul>
  </div>

  <!-- <div class="col-12 col-md-3">
    <ul class="pricing highlighted">
      <li class="pricing-title">
        <h1>Premium</h1>
      </li>
      <li>
        <p>
          Our recommended plan for HR or Talent Teams looking for more
          connections than our Starter and Standard plans
        </p>
      </li>
      <li>50 connections</li>
      <li>To be used within 6 months</li>
      <li>Logo on website</li>

      <li class="empty">&nbsp;</li>
      <li>
        <h3 style="margin-top: 0">&pound;8,950</h3>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-primary btn-rounded"
          (click)="selectPackage('gold')"
        >
          Upgrade
        </button>
      </li>
    </ul>
  </div>

  <div class="col-12 col-md-3">
    <ul class="pricing">
      <li class="pricing-title">
        <h1>Utlimate</h1>
      </li>
      <li>
        <p>
          Our Utlimate package is for Companies who are serious about changing
          the way they approach Recruitment and investing in long-term access to
          immediate resources, as well as Featuring in our Learning Centre
        </p>
      </li>
      <li>100 connections</li>
      <li>To be used within 12 months</li>
      <li>Logo on website</li>
      <li>Feature story</li>
      <li>
        <h3 style="margin-top: 0">&pound;15,900</h3>
      </li>
      <li>
        <button
          type="button"
          class="btn btn-primary btn-rounded"
          (click)="selectPackage('platinum')"
        >
          Upgrade
        </button>
      </li>
    </ul>
  </div> -->
</div>
<!-- /row -->
<!-- 
<div class="row">
  <div class="col-md-6">
    <div class="card stat-card">
      <div class="card-body">
       
      </div>
    </div>
  </div>
</div> -->
