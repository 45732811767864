import { ConversationLastMessageTimePipe } from './conversation-last-message-time/conversation-last-message-time.pipe';

import { InvitationDatetimePipe } from './invitation-datetime/invitation-datetime.pipe';
import { NgModule } from '@angular/core';
import { TtCurrencyPipe } from './tt-currency/tt-currency.pipe';
import { TtAvailabilityDurationPipe } from './tt-availability-duration/tt-availability-duration.pipe';
@NgModule({
  declarations: [
    InvitationDatetimePipe,
    ConversationLastMessageTimePipe,
    TtCurrencyPipe,
    TtAvailabilityDurationPipe,
  ],
  imports: [],
  exports: [
    InvitationDatetimePipe,
    ConversationLastMessageTimePipe,
    TtCurrencyPipe,
    TtAvailabilityDurationPipe,
  ],
  providers: [
    InvitationDatetimePipe,
    ConversationLastMessageTimePipe,
    TtCurrencyPipe,
    TtAvailabilityDurationPipe,
  ],
})
export class PipesModule {}
