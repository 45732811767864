<div class="login-page">
  <div class="container">
    <div class="login-container">
      <div class="row">
        <div class="col-lg-4 col-md-5 col-sm-9 lfh">
          <div class="card login-box">
            <div class="card-body">
              <h5 class="card-title">Verify your email address</h5>
              <p>
                We have sent you an email for verification. Click on the link
                provided to continue.
              </p>
              <button class="btn" (click)="logout()">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
