<nav aria-label="Pagination">
  <ul class="pagination pagination-circle justify-content-center">
    <li class="page-item">
      <button
        class="page-link"
        (click)="previousPage()"
        [disabled]="currentPage == 0"
      >
        Previous
      </button>
    </li>
    <li
      class="page-item page-number"
      [class.active]="pageNum == currentPage"
      *ngFor="let pageI of numberOfPagesarray; let pageNum = index"
    >
      <a class="page-link" (click)="goToPage(pageNum)">{{ pageNum + 1 }}</a>
    </li>

    <li class="page-item">
      <button
        class="page-link"
        (click)="nextPage()"
        [disabled]="currentPage >= pageCount - 1"
      >
        Next
      </button>
    </li>
  </ul>
</nav>
