import { CitiesProvider } from './../../tt-models/cities.model';
import { FreeMailProvider } from './../../models/free-mail-providers.mode';
import { UserService } from './../../tt-services/user/user.service';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/tt-services/auth/auth.service';
import csc from 'country-state-city';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
})
export class RegisterPageComponent implements OnInit {
  companyProfileFormGroup;
  accountFormGroup: FormGroup;
  step = 'profile_setup';
  errorMsg = null;
  allCities = [];

  procesing = false;

  validation_messages = {
    companyName: [{ type: 'required', message: 'Company name is required.' }],
    companyNumber: [
      { type: 'required', message: 'Company number is required.' },
    ],
    address_1: [{ type: 'required', message: 'Address is required' }],
    address_city: [{ type: 'required', message: 'City is required' }],
    address_postcode: [
      { type: 'required', message: 'Postcode is required' },
      {
        type: 'pattern',
        message: 'You entered an invalid postcode.',
      },
    ],
    company_telephone: [{ type: 'required', message: 'Telephone is required' }],
    company_website: [{ type: 'required', message: 'Website is required' }],
    company_size: [{ type: 'required', message: 'Company Size is required' }],
    company_industry: [{ type: 'required', message: 'Industry is required' }],

    account_password: [
      { type: 'required', message: 'Password is required.' },
      {
        type: 'minlength',
        message: 'Please choose a password with a minimum of 6 characters.',
      },
    ],
    account_firstname: [
      { type: 'required', message: 'Firstname is required.' },
    ],
    account_lastname: [{ type: 'required', message: 'Lastname is required.' }],
    account_email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'email', message: 'Email is not valid.' },
      {
        type: 'emailInUse',
        message: 'This email has already been used to register an account.',
      },
      {
        type: 'companyEmailValidator',
        message: 'You have entered an invalid company email.',
      },
    ],
    account_role: [{ type: 'required', message: 'Role is required.' }],
    account_password_repeat: [
      { type: 'required', message: 'Password confirmation is required.' },
      {
        type: 'confirmPasswordValidator',
        message: "Password confirmation doesn't match password",
      },
    ],
  };

  postcodePattern =
    '^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.companyProfileFormGroup = this.fb.group({
      company_name: fb.control('', [Validators.required]),
      company_number: fb.control('', [Validators.required]),
      address_1: fb.control('', [Validators.required]),
      address_2: fb.control(''),
      address_city: fb.control(null, [Validators.required]),
      address_postcode: fb.control('', [
        Validators.required,
        Validators.pattern(this.postcodePattern),
      ]),
      company_telephone: fb.control('', [Validators.required]),
      company_website: fb.control('', [Validators.required]),
      company_size: fb.control(null, [Validators.required]),

      // company_industry: fb.control('', [Validators.required]),
    });
    this.accountFormGroup = this.fb.group({
      account_email: fb.control('', [Validators.email]),
      account_password: fb.control('', [
        Validators.required,
        Validators.min(6),
      ]),
      account_password_repeat: fb.control('', [
        Validators.required,
        this.passwordMatchValidator,
      ]),
      account_firstname: fb.control('', [Validators.required]),
      account_lastname: fb.control('', [Validators.required]),
      account_role: fb.control(null, [Validators.required]),
    });
  }

  companyEmailValidator(c: FormControl) {
    if (!c.parent) return;
    let control = c.parent.get('account_email');

    const isFreeEmailProvider = FreeMailProvider.isFreeMail(control.value);

    if (!isFreeEmailProvider) {
      return null;
    } else {
      return { companyEmailValidator: true };
    }
  }

  passwordMatchValidator(c: FormControl) {
    if (!c.parent) return;
    let control = c.parent.get('account_password');
    let matchingControl = c.parent.get('account_password_repeat');
    if (
      matchingControl.errors &&
      !matchingControl.errors.confirmPasswordValidator
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      return { confirmPasswordValidator: true };
    } else {
      return null;
    }
  }

  ngOnInit(): void {
    // const states = csc.getStatesOfCountry('230');
    // let cities = [];

    //   states.forEach((state) => {
    //     cities = cities.concat(csc.getCitiesOfState(state.id));
    //   });

    //   cities.sort((cityA, cityB) => {
    //     if (cityA.name < cityB.name) {
    //       return -1;
    //     }
    //     if (cityA.name > cityB.name) {
    //       return 1;
    //     }
    //     return 0;
    //   });
    this.allCities = CitiesProvider.CITIES;
  }

  nextStage() {
    if (!this.companyProfileFormGroup.valid) {
      Object.keys(this.companyProfileFormGroup.controls).forEach((field) => {
        // {1}
        const control = this.companyProfileFormGroup.get(field); // {2}
        control.markAsTouched({ onlySelf: true }); // {3}
      });
      this.companyProfileFormGroup.updateValueAndValidity();
      return;
    }
    this.step = 'account_setup';
  }

  async onSubmit() {
    this.procesing = true;
    this.accountEmail.setValidators([
      Validators.required,
      Validators.email,
      this.companyEmailValidator,
    ]);
    this.accountEmail.updateValueAndValidity();

    if (!this.accountFormGroup.valid) {
      Object.keys(this.accountFormGroup.controls).forEach((field) => {
        // {1}
        const control = this.accountFormGroup.get(field); // {2}
        control.markAsTouched({ onlySelf: true }); // {3}
      });
      this.accountFormGroup.updateValueAndValidity();
      this.procesing = false;
      return;
    }
    try {
      const user = await this.authService.registerUser(
        this.accountEmail.value,
        this.accountPassword.value,
        this.displayName
      );

      if (user) {
        await this.userService.createBusinessAccount(user.uid, {
          email: this.accountEmail.value,
          address: this.address,
          firstname: this.firstname.value,
          lastname: this.lastname.value,
          role: this.role.value,
          businessName: this.companyName.value,
          businessNumber: this.companyNumber.value,
          telephoneNumber: this.telephoneNumber.value,
          website: this.website.value,
          companySize: this.companySize.value,
          industry: { id: 22, name: 'Information Technology' },
        });

        setTimeout(() => {
          this.procesing = false;
          this.router.navigate(['/verify-business']);
        }, 3000);
      }
    } catch (e) {
      this.procesing = false;
      switch (e.code) {
        case 'auth/email-already-in-use':
          this.accountEmail.setValidators([
            Validators.required,
            Validators.email,
            this.emailInUserValidator,
          ]);
          this.accountEmail.updateValueAndValidity();
          setTimeout(() => {
            this.accountEmail.setValidators([
              Validators.required,
              Validators.email,
            ]);
          }, 1000);
          break;
      }
    }

    // this.authService
    //   .authenticateUserWithPassword(loginData['email'], loginData['password'])
    //   .then((data) => {
    //     this.router.navigate(['dashboard']);
    //   })
    //   .catch((reason: any) => {
    //     console.log(reason);
    //   });
  }

  emailInUserValidator(c: FormControl) {
    return {
      emailInUse: {
        valid: false,
      },
    };
  }

  get displayName() {
    return (
      this.accountFormGroup.get('account_firstname').value +
      ' ' +
      this.accountFormGroup.get('account_lastname').value
    );
  }

  get companyName() {
    return this.companyProfileFormGroup.get('company_name');
  }

  get companyNumber() {
    return this.companyProfileFormGroup.get('company_number');
  }

  get telephoneNumber() {
    return this.companyProfileFormGroup.get('company_telephone');
  }

  get website() {
    return this.companyProfileFormGroup.get('company_website');
  }

  get companySize() {
    return this.companyProfileFormGroup.get('company_size');
  }

  get industry() {
    return this.companyProfileFormGroup.get('company_industry');
  }

  get firstname() {
    return this.accountFormGroup.get('account_firstname');
  }

  get lastname() {
    return this.accountFormGroup.get('account_lastname');
  }

  get role() {
    return this.accountFormGroup.get('account_role');
  }

  get address1() {
    return this.companyProfileFormGroup.get('address_1');
  }

  get address2() {
    return this.companyProfileFormGroup.get('address_2');
  }

  get addressCity() {
    return this.companyProfileFormGroup.get('address_city');
  }

  get addressPostcode() {
    return this.companyProfileFormGroup.get('address_postcode');
  }

  get address() {
    const address1 = this.companyProfileFormGroup.get('address_1').value;
    const address2 = this.companyProfileFormGroup.get('address_2').value;
    const city = this.companyProfileFormGroup.get('address_city').value;
    const postcode = this.companyProfileFormGroup.get('address_postcode').value;

    return {
      city: city,
      country: 'GB',
      line1: address1,
      line2: address2,
      postcode: postcode,
      county: '',
    };
  }

  get accountEmail() {
    return this.accountFormGroup.get('account_email');
  }

  get accountPassword() {
    return this.accountFormGroup.get('account_password');
  }

  get accountPasswordRepeat() {
    return this.accountFormGroup.get('account_password_repeat');
  }
}
