import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ttspinner',
  templateUrl: './ttspinner.component.html',
  styleUrls: ['./ttspinner.component.scss'],
})
export class TtspinnerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
