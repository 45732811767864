<div class="login-page">
  <div class="container">
    <div class="login-container">
      <div class="row">
        <div class="col-lg-4 col-md-5 col-sm-9 lfh">
          <div class="card login-box">
            <div class="card-body">
              <h5 class="card-title">Registration Complete</h5>
              <p>
                Thank you for registering! We’re verifying your company details
                and you will receive an email very shortly with a link to log
                into your account.
              </p>
              <button class="btn btn-primary" (click)="logout()">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
