import { UserType } from "./../../tt-models/user.model";
import { Router } from "@angular/router";
import { UserService } from "./../user/user.service";
import { Observable, of, empty, combineLatest, throwError } from "rxjs";
import { Injectable } from "@angular/core";

import { AngularFireAuth } from "@angular/fire/auth";
import { User } from "src/app/tt-models/user.model";
import { map, flatMap, catchError, tap } from "rxjs/operators";
import { Candidate } from "src/app/tt-models/candidate.model";
import * as firebase from "firebase";
import { AngularFireFunctions } from "@angular/fire/functions";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentUserObserver: Observable<User>;
  currentUser: User;

  constructor(
    public auth: AngularFireAuth,
    private userService: UserService,
    private router: Router,
    private fbf: AngularFireFunctions
  ) {
    this.currentUserObserver = auth.user
      .pipe(
        map((state) => {
          if (state) {
            if (state.emailVerified || true) {
              return userService
                .getUser(state.uid)
                .pipe(
                  catchError((err) => {
                    return throwError(err);
                  })
                )
                .pipe(
                  map((user) => {
                    user.email = state.email;
                    this.currentUser = user;
                    return user;
                  })
                );
            } else {
              return throwError("email-not-verified");
            }
          } else {
            return of(null);
          }
        })
      )
      .pipe(
        flatMap((user) => {
          return user;
        })
      );

    this.currentUserObserver.subscribe((user) => {
      if (user) {
        if (user.type == UserType.BUSINESS && !user.verified) {
          this.router.navigate(["/verify-business"]);
          return;
        }

        if (user.type == UserType.CANDIDATE && !user.onboarded) {
          this.router.navigate(["/onboard"]);
        }
      }
    });
  }

  sendQueryMessage(message: string) {
    const callable = this.fbf.httpsCallable("sendQueryMessage");

    return callable({
      type: this.currentUser.type,
      name: this.currentUser.fullName,
      email: this.currentUser.email,
      message: message,
    });
  }

  async updatePassword(newPassword: string) {
    const user = await this.auth.currentUser;
    return user.updatePassword(newPassword);
  }

  closeAccount() {
    const callable = this.fbf.httpsCallable("closeAccount");
    return callable({});
  }

  async verifyPhoneNumber(
    number,
    recaptchaVerifier: firebase.default.auth.ApplicationVerifier
  ) {
    const user = await this.auth.currentUser;
    try {
      return await user.reauthenticateWithPhoneNumber(
        number,
        recaptchaVerifier
      );
      return;
    } catch (e) {}
  }

  // getCurrentUserProfile() {
  //   return this.currentUser
  //     .pipe(
  //       map((user) => {
  //         return this.userService.getUser(user.id).pipe(
  //           map((userProfile) => {
  //             return userProfile;
  //           })
  //         );
  //       })
  //     )
  //     .pipe(
  //       flatMap((user) => {
  //         return user;
  //       })
  //     );
  // }

  logout() {
    return this.auth.signOut();
  }

  authenticateUserWithPassword(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  async registerUser(email: string, password: string, displayName: string) {
    const userCredential = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );

    userCredential.user.updateProfile({ displayName });

    return { uid: userCredential.user.uid };
  }

  async registerBusiness(email: string, password: string) {
    try {
      const userCredential = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      );

      await userCredential.user.sendEmailVerification({
        url: "http://localhost:4200/",
      });

      return { uid: userCredential.user.uid };
    } catch (e) {}
  }
}
