import { Router } from '@angular/router';
import { PaymentService } from './../../tt-services/payment/payment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
} from '@angular/core';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-upgrade-page',
  templateUrl: './upgrade-page.component.html',
  styleUrls: ['./upgrade-page.component.scss'],
})
export class UpgradePageComponent implements OnInit {
  constructor(private router: Router) {}

  selectPackage(subscriptionPackage: string) {
    this.router.navigate(['/upgrade/' + subscriptionPackage + '/pay']);
  }

  ngOnInit(): void {}
}
