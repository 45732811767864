import { ForgottenpasswordComponent } from './pages/forgottenpassword/forgottenpassword.component';
import { VerifyBusinessPageComponent } from './pages/verify-business-page/verify-business-page.component';
import { VerifyEmailPageComponent } from './pages/verify-email-page/verify-email-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
  emailVerified,
} from '@angular/fire/auth-guard';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { map } from 'rxjs/operators';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['dashboard']);

const redirectVerifiedToDashboard = () =>
  map((user: firebase.default.User) => {
    if (!user) {
      return [''];
    }
    return !user.emailVerified ? true : ['dashboard'];
  });

const redirectUnauthorized = () =>
  map((user: firebase.default.User) => {
    if (!user) {
      return ['login'];
    }
    return user.emailVerified ? true : true;
  });

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    canActivate: [AngularFireAuthGuard],
    component: LoginPageComponent,
    data: { authGuardPipe: redirectLoggedInToDashboard },
  },
  {
    path: 'forgotten-password',
    canActivate: [AngularFireAuthGuard],
    component: ForgottenpasswordComponent,
    data: { authGuardPipe: redirectLoggedInToDashboard },
  },
  {
    path: 'verify-email',
    canActivate: [AngularFireAuthGuard],
    component: VerifyEmailPageComponent,
    data: { authGuardPipe: redirectVerifiedToDashboard },
  },
  {
    path: 'verify-business',
    canActivate: [AngularFireAuthGuard],
    component: VerifyBusinessPageComponent,
    data: { authGuardPipe: redirectVerifiedToDashboard },
  },
  {
    path: 'register',
    canActivate: [AngularFireAuthGuard],
    component: RegisterPageComponent,
    data: { authGuardPipe: redirectLoggedInToDashboard },
  },
  {
    path: '',
    canActivate: [AngularFireAuthGuard],
    component: HomePageComponent,
    data: { authGuardPipe: redirectUnauthorized },
    loadChildren: () =>
      import('./pages/home-page/home-page.module').then(
        (m) => m.HomePageModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
