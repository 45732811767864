import { UserService } from './../../tt-services/user/user.service';
import { Business } from './../../tt-models/business.model';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/tt-services/auth/auth.service';
import { Observable } from 'rxjs';
import { User } from 'src/app/tt-models/user.model';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  currentUserObserver: Observable<User>;

  businessUser: User;
  remainingTrialDays = 0;
  showMobileMenu = false;

  unreadMessageCount = 0;
  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.currentUserObserver = this.authService.currentUserObserver;
    this.currentUserObserver.subscribe((currentUser) => {
      this.loadUser(currentUser);
    });

    //Monitor subscription changes
  }

  loadUser(currentUser: User) {
    this.businessUser = currentUser;

    this.unreadMessageCount = currentUser.unreadMessageCount;

    this.userService.watchUser(currentUser.id).subscribe((user) => {
      this.businessUser = user;
      this.unreadMessageCount = user.unreadMessageCount;

      const registerdDate = new Date(user.registeredOn);
      const todaysDate = new Date();

      this.remainingTrialDays =
        15 -
        Math.round(
          (todaysDate.getTime() - registerdDate.getTime()) /
            (1000 * 60 * 60 * 24)
        );
    });
  }

  async toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  resetUnread() {
    this.businessUser.lastMessageReadAt = new Date();
    this.businessUser.unreadMessageCount = 0;
  }

  async logout() {
    await this.authService.logout();
    this.router.navigate(['login']);
  }
}
