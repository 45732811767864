import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/tt-services/auth/auth.service';

@Component({
  selector: 'app-forgottenpassword',
  templateUrl: './forgottenpassword.component.html',
  styleUrls: ['./forgottenpassword.component.scss'],
})
export class ForgottenpasswordComponent implements OnInit {
  loginFormGroup;
  errorMsg = null;
  success = null;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {
    this.loginFormGroup = this.formBuilder.group({
      email: '',
      password: '',
    });
  }

  ngOnInit(): void {}

  onSubmit(loginData) {
    this.errorMsg = null;

    this.authService.auth
      .sendPasswordResetEmail(loginData['email'])
      .then(async (data) => {
        this.success =
          'A reset email has been sent. Please check for password reset instructions.';

        // this.router.navigate(['']);
      })
      .catch((reason: any) => {
        switch (reason.code) {
          case 'auth/invalid-email':
            this.errorMsg = 'You have entered an invalid email.';
            break;
          case 'auth/user-not-found':
            this.errorMsg = 'You have entered an invalid email.';
            break;
          default:
            this.errorMsg =
              'Talent Toute encountered an issue while resetting your password. Please try again.';
            break;
        }
      });
  }
}
